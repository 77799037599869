import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'intersection-observer';

import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/tooltip';
// import 'admin-lte/build/js/ControlSidebar';

import bsCustomFileInput from 'bs-custom-file-input';

import Swal from 'sweetalert2';

/* Toolbar dispo CKE : "undo", "redo", "bold", "italic", "blockQuote", "ckfinder", "imageTextAlternative", "imageUpload", "heading", "imageStyle:full", "imageStyle:side", "link", "numberedList", "bulletedList", "mediaEmbed", "insertTable", "tableColumn", "tableRow", "mergeTableCells" */

document.addEventListener('DOMContentLoaded', function () {
	// Liens externe à ouvrir dans une autre fenêtre
	document.querySelectorAll('.lien_externe, .lien-ext').forEach(lien => {
		if (lien.href) {
			lien.addEventListener('click', evt => {
				evt.preventDefault();
				window.open(lien.href);

				return false;
			});
		}
	});

	// Sommaires
	$('#sommaire, #sommaire_flux').on('click', 'a', function (evt) {
		evt.preventDefault();
		let hauteurMenu = $('.container-menu').height(),
			cible = $(this).attr('href').split('#')[1];
		$('html, body').animate({
			scrollTop: $('#' + cible + ', [name="' + cible + '"]').offset().top - hauteurMenu
		}, 250);
	});

	// Changer le chevron des collapse
	$('body').on('show.bs.collapse', function (e) {
		var monPanel = $(e.target)/*,
			maSource = $('[href="#' + monPanel.attr('id') + '"]')*/;
			
		monPanel.parent().find('.fa-chevron-right').first()
			.removeClass('fa-chevron-right')
			.addClass('fa-chevron-down');
	}).on('hide.bs.collapse', function (e) {
		var monPanel = $(e.target)/*,
			maSource = $('[href="#' + monPanel.attr('id') + '"]')*/;
			
		monPanel.parent().find('.fa-chevron-down').first()
			.removeClass('fa-chevron-down')
			.addClass('fa-chevron-right');
	});

	// Changer le texte dans les file input
	bsCustomFileInput.init();

	// Recherche
	if (document.querySelector('#idUser')) {
		const homeURL = document.querySelector('#idUser').dataset.home;

		// Ajouter des data au préalable pour savoir quand afficher le sous-menu ou pas
		if ($('.sous-menu').hasClass('show')) {
			$('.sous-menu').data('affiche', true);
			document.querySelector('.sous-menu').dataset.affiche = true;
		} else {
			$('.sous-menu').data('affiche', false);
			document.querySelector('.sous-menu').dataset.affiche = false;
		}
		// Afficher le champs de recherche au clic ou le fermer
		if (document.querySelector('.search-btn')) {
			document.querySelector('.search-btn').addEventListener('click', () => {
				document.querySelector('.recherche').classList.add('show');
				document.querySelector('.sous-menu').classList.remove('show');
			});
			document.querySelector('.fermer i').addEventListener('click', () => {
				if (document.querySelector('.sous-menu').dataset.affiche) {
					document.querySelector('.sous-menu').classList.add('show');
				}
				document.querySelector('.recherche').classList.remove('show');
			});
		}

		document.querySelector('.search-form').addEventListener('click', evt => {
			evt.preventDefault();
			if (evt.target.classList.contains('fa-search')) {
				window.location.href = homeURL + 'recherche/' + document.querySelector('.search-form input').value;
			}
		});
		document.querySelector('.search-form').addEventListener('keypress', evt => {
			if (evt.target.classList.contains('form-control') && evt.keyCode === 13) {
				evt.preventDefault();
				window.location.href = homeURL + 'recherche/' + document.querySelector('.search-form input').value;
			}
		});
	}

	// Tooltips du menu
	$('.menu-haut').find('[data-toggle="tooltip"]').tooltip();

	// Lier les actions sur une éval dupliquée
	if (document.querySelector('#dupliQaction') && document.querySelector('#dupliQignore')) {
		document.querySelector('#dupliQaction').addEventListener('click', evt => {
			evt.preventDefault();
			let userURL = document.querySelector('#idUser').dataset.home;
			
			// Y veut dupliquer, dupliquons
			const fdDuplique = new FormData();
			fdDuplique.append('old_eval', document.querySelector('#dupliQaction').dataset.old);
			fdDuplique.append('new_eval', document.querySelector('#dupliQaction').dataset.new);
			fetch(userURL + 'api/actions/toNewEval', {
				method: 'POST',
				body: fdDuplique
			})
				.then(res => res.json())
				.catch(err => {
					console.error(err);

					return {
						state: 0
					};
				})
				.then(data => {
					if (data.state === 1) {
						Swal.fire({
							title: 'Réussite de l\'opération',
							text: 'Actions dupliquées, vous allez être redirigé(e) vers la page d\'accueil.',
							icon: 'success'
						});
						document.location.href = userURL;
					} else {
						Swal.fire({
							title: 'Echec de l\'opération',
							text: 'Une erreur est survenue.',
							icon: 'error'
						});
					}
				});
		});
		document.querySelector('#dupliQignore').addEventListener('click', evt => {
			evt.preventDefault();
			let userURL = document.querySelector('#idUser').dataset.home;

			Swal.fire({
				title: 'Réussite de l\'opération',
				text: 'Vous allez être redirigé(e) vers la page d\'accueil.',
				icon: 'info'
			});
			document.location.href = userURL;
		});
	}

	// Faire retour auto sur les impressions
	// if (document.querySelector('.back_impress')) {
	// 	document.querySelector('iframe').addEventListener('load', () => {
	// 		window.history.back(-1);
	// 	});
	// }

	// Autoload les iframes
	document.querySelectorAll('iframe').forEach(ifr => {
		ifr.addEventListener('load', () => {
			ifr.classList.add('loaded');
		});
	});

	// Gérer les alertes
	if (document.querySelectorAll('[data-dismiss="alert"]').length > 0) {
		// eslint-disable-next-line no-undef
		require('bootstrap/js/dist/alert');
	}
});
